<template>
  <navigation-tabs>
  <v-container class="d-flex fill-height align-start mt-4 px-8">
    <v-row class="pt-4 px-4">
      <span v-if="selectedHouseholdType != '注文戸建住宅'" class="el_centered_h5 text-h5">
        報告⽅法を選択してください。
      </span>
      <span v-else class="text-h5 el_centered_h5">
        注文戸建住宅の報告⽅法は以下の通りです。
      </span>
    </v-row>
    <v-row 
     v-if="selectedHouseholdType == '注文戸建住宅'"
     class="d-flex align-center mt-5 mb-12 pa-0" style="flex-direction: column;
      justify-content: center;">
      <v-col cols=12 align="center" class="">
        <v-card
          width=100%
          class="px-8 py-4"
          outlined>
          <v-card-title>
            <v-icon>mdi-alert-circle</v-icon>
            {{ selectedHouseholdType }}の報告⽅法
          </v-card-title>
            <v-card-text
              class="text-justify">
              <div>
                <p class="font-weight-bold mb-2">
                  住宅ごとにエネルギー消費性能計算結果のファイルを提出（ファイル数＝新築⼾数）してください。
                </p>
              </div>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
    v-if="selectedHouseholdType == '建売戸建住宅'"
    class="d-flex justify-center pt-8 bl_repotType_cardWrapper"
    >
      <v-col v-for="(type, index) in reportTypes"
        :key="index"
        cols=12 md=6 lg="6"
        class="bl_repotType_card">
        <v-card
        width=100%
        class="px-8 py-6"
        outlined>
          <div v-if="type == '報告方法i'">
            <div class="bl_repotType_card_imgContent">
              <div class="d-flex justify-center align-cener">
                <span class="text-no-wrap">住宅ごとの算定結果の</span>
                <span class="text-no-wrap">ファイルを提出する方法。</span>
              </div>
              <img src="../assets/img/tr_reportType_1_2x.png" />
            </div>
          </div>
          <div v-if="type == '報告方法ii'">
            <div class="bl_repotType_card_imgContent">
              <div class="d-flex justify-center align-cener">
                <span class="text-no-wrap">住宅タイプおよび地域区分ごとの</span><br />
                <span class="text-no-wrap">算定結果のファイルを</span>
                <span class="text-no-wrap">提出する方法。</span>
              </div>
              <img src="../assets/img/tr_reportType_2_kodate_2x.png" />
            </div>
          </div>
          <v-btn
            @click="selectReportType(type)"
            width="320"
            max-width="100%"
            height="100"
            color="#5E539A"
            class="my-0 mx-auto">
              <span class="text-h5 text-lowercase white--text"> {{ type }}</span>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row
    v-if="selectedHouseholdType == '賃貸アパート・分譲マンション'"
    class="d-flex justify-center pt-8 bl_repotType_cardWrapper"
    >
      <v-col v-for="(type, index) in reportTypes"
        :key="index"
        cols=12 md=6
        class="bl_repotType_card">
        <v-card
        width=100%
        class="px-8 py-6"
        outlined>
          <div v-if="type == '報告方法i'">
            <div class="bl_repotType_card_imgContent">
              <div class="d-flex justify-center align-cener">
                <span class="text-no-wrap">住戸ごとの算定結果の</span>
                <span class="text-no-wrap">ファイルを提出する方法。</span>
              </div>
              <img src="../assets/img/tr_reportType_1_2x.png" />
            </div>
          </div>
          <div v-if="type == '報告方法ii'">
            <div class="bl_repotType_card_imgContent">
              <div class="d-flex justify-center align-cener">
                <span class="text-no-wrap">住棟ごとに最不利側住戸の算定結果の</span>
                <span class="text-no-wrap">ファイルを提出する方法。</span>
              </div>
              <img src="../assets/img/tr_reportType_2_kyodo_2x.png" />
            </div>
          </div>
          <v-btn
            @click="selectReportType(type)"
            width="320"
            max-width="100%"
            height="100"
            color="#5E539A"
            class="my-0 mx-auto">
              <span class="text-h5 text-lowercase white--text"> {{ type }}</span>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>




    <v-row
      v-else
      class="d-flex pt-10"
      style="column-gap:clamp(2px, 2vw, 1rem);">
      <v-col
        v-if="selectedHouseholdType == '注文戸建住宅'">
        <v-col align="center">
          <v-btn
          @click="selectReportType('')"
          width="250"
          height="75"
          color="#5E539A">
            <span class="text-h5 white--text">次へ</span>
          </v-btn>
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mx-0 mt-10 pa-0 bl_fixedFooter">
      <v-btn
        to="/household-type"
        plain
        height="60"
        class="font-weight-bold text-h6 pl-0">
        <v-icon large>mdi-chevron-left</v-icon>
          戻る
      </v-btn>
    </v-row>
  </v-container>
  </navigation-tabs>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import NavigationTabs from '@/components/templates/NavigationTabs.vue'

export default {
  components: {
    NavigationTabs,
  },
  computed: {
    ...mapGetters([
      'selectedHouseholdType',
      'reportTypes'
    ]),

    // 報告⽅法の詳細リンク
    url() {
        if (this.selectedHouseholdType == '建売戸建住宅') return 'https://www.mlit.go.jp/jutakukentiku/house/content/001412242.pdf'
        if (this.selectedHouseholdType == '注文戸建住宅') return 'https://www.mlit.go.jp/jutakukentiku/house/content/001412243.pdf'
        return 'https://www.mlit.go.jp/jutakukentiku/house/content/001412245.pdf'
    }
  },

  methods: {
    ...mapActions(['update']),

    // 報告⽅法を選択する処理
    selectReportType(type) {
      this.update({selectedReportType:type})
      this.$router.push('/file-upload')
    }
  }
}
</script>

<style scoped>
.bl_repotType_card >>> .v-card{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(150px, auto) auto;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.bl_repotType_card_imgContent{
  display: flex;
  column-gap: 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 16px;
}
.bl_repotType_card_imgContent>div{
  width: 100%;
  min-width:10em;
  min-height: 3em;
  flex-wrap: wrap;
}
.bl_repotType_card_imgContent>img{
  width: 130px;
  height: auto;
}
.bl_repotType_card_details{
  width: fit-content;
  margin: 0 auto;
  font-size: 0.8em;
}
</style>