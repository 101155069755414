<template>
    <v-tabs grow center-active 
    height="70px"
    color="grey" slider-color="primary" class="pt-2 bl_tabs">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact class="button text-subtitle-2 font-weight-bold primary--text"
       style="flex-direction: column; align-items:center; justify-content: center; font-size:1rem !important; border-bottom: #efefef solid 3px;" disabled>
        {{ tab.name }}
        <br />
        <span v-if="tab.name=='建て方選択' && selectedHouseholdType" class="bl_tabs_tab_selected">{{selectedHouseholdType}}</span>
        <span v-if="tab.name=='報告方法選択' && selectedReportType" class="bl_tabs_tab_selected">{{selectedReportType}}</span>
        <span v-if="tab.name=='ファイルアップロード' && selectedReportType&& fileCnt !== 0" class="bl_tabs_tab_selected">合計 {{ fileCnt }} ファイル</span>
        
      </v-tab>
      <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route">
        <slot></slot>
      </v-tab-item>
    </v-tabs>
</template>

<script>
import { tabs } from '@/util'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'selectedHouseholdType',
      'selectedReportType',
      'fileCnt',
    ])
  },
  data() {
    return {
      // ナビゲーション
      tabs: tabs
    }
  },
}
</script>
